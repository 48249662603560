import {
  clearInputField,
} from "../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form").first();

  if ($form.length === 0) {
    return;
  }

  const optionalDcoument = ".optional-document";
  const removeButton = ".remove-document";
  const destroyDocumentCheckbox = "input[type=checkbox].destroy_document_checkbox";
  const seeDocumentButton = ".see-document";

  $(`${optionalDcoument} input[type=file]`, $form).on("change", (e) => {
    let $input = $(e.target);
    let $optionalDocument = $input.closest(optionalDcoument);
    let $removeButton = $(removeButton, $optionalDocument);
    let $destroyCheckbox = $(destroyDocumentCheckbox, $optionalDocument);

    $removeButton.removeClass("d-none");

    $destroyCheckbox[0].checked = false;
  });

  $(`${optionalDcoument} ${removeButton}`, $form).on("click", (e) => {
    let $removeButton = $(e.target);
    let $optionalDocument = $removeButton.closest(optionalDcoument);
    let $input = $("input[type=file]", $optionalDocument);
    let $destroyCheckbox = $(destroyDocumentCheckbox, $optionalDocument);

    $removeButton.addClass("d-none");
    $removeButton.siblings(seeDocumentButton).remove();

    clearInputField($input[0]);

    $destroyCheckbox[0].checked = true;
  });
});
