$(document).ready(() => {
  $('select').select2();

  $("select").on("select2:select select2:unselect select2:clear", (e) => {
    $(e.target).next(".select2").toggleClass("filled", e.target.value !== "");
  });

  $("select").each((_, element) => {
    $(element).next(".select2").toggleClass("filled", element.value !== "");
  });
});
