import {
  getQueryStringParameter,
  clearInputField,
  clearSelectField
} from "../../../helpers";

const hideAndClearFatcaInputs = ($form) => {
  const $fatcaInputs = $("#fatca-inputs", $form);
  const $fatcaFiscalCountries = $(".fatca-fiscal-country", $fatcaInputs);
  const $fatcaFiscalCountry2 = $("#fatca-fiscal-country2", $fatcaInputs);
  const $fatcaFiscalCountry3 = $("#fatca-fiscal-country3", $fatcaInputs);
  const $addFatcaFiscalCountryButton = $("#add-fatca-fiscal-country", $fatcaInputs);

  $fatcaInputs.addClass("d-none");
  $fatcaFiscalCountry2.addClass("d-none");
  $fatcaFiscalCountry3.addClass("d-none");
  $addFatcaFiscalCountryButton.removeClass("d-none");

  $("input", $fatcaFiscalCountries).each((_, inputElement) => {
    clearInputField(inputElement);
  });

  const changeEvent = new Event("change");

  $("select", $fatcaFiscalCountries).each((_, inputElement) => {
    clearInputField(inputElement);
    inputElement.selectedIndex = 0
    inputElement.dispatchEvent(changeEvent);
  });
};

$(document).ready(() => {
  const $form = $("form#natural-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "fatca") {
    return;
  }

  const $isUsCitizenInputs = $("#is-us-citizen-inputs input[type=radio]", $form);
  const $isUsCitizenWarning = $("#is-us-citizen-warning", $form);
  const $fatcaInputs = $("#fatca-inputs", $form);
  const $hasFiscalResidenceOutsideFranceInputs = $("#has-fiscal-residence-outside-france-inputs input[type=radio]", $form);

  $isUsCitizenInputs.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "true") {
      $isUsCitizenWarning.removeClass("d-none");
      $fatcaInputs.removeClass("d-none");
    } else {
      $isUsCitizenWarning.addClass("d-none");

      if ($hasFiscalResidenceOutsideFranceInputs.filter(":checked").val() === "false") {
        hideAndClearFatcaInputs($form);
      }
    }
  });

  $hasFiscalResidenceOutsideFranceInputs.on("change", (e) => {
    if (!e.target.checked || $isUsCitizenInputs.filter(":checked").val() === "true") return;

    if (e.target.value === "true") {
      $fatcaInputs.removeClass("d-none");
    } else {
      hideAndClearFatcaInputs($form);
    }
  });
});
