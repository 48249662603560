$(document).ready(() => {
  const $defaultProfileForm = $("#default-profile-form");
  const defaultProfileFormActionTemplate = $defaultProfileForm.attr("action");

  $("#default-profile-modal").on("show.bs.modal", (e) => {
    let profileID = e.relatedTarget.dataset.profileId;
    let defaultProfileFormAction = defaultProfileFormActionTemplate.replace(":profile_id", profileID)

    $defaultProfileForm.attr("action", defaultProfileFormAction);
  });
});
