import {
  clearInputField,
  clearSelectField
} from "../../../helpers";

$(document).ready(() => {
  const $hasAuthorizedRepresentativeCheckbox = $("#has-authorized-representatives", "#natural-profile-form");
  const $authorizedRepresentativeFields = $("#authorized-representatives-fields", "#natural-profile-form");

  $hasAuthorizedRepresentativeCheckbox.on("change", e => {
    $authorizedRepresentativeFields.toggleClass("d-none", !e.target.checked);

    if (!e.target.checked) {
      $("input", $authorizedRepresentativeFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });

      $("select", $authorizedRepresentativeFields).each((_, selectElement) => {
        clearSelectField(selectElement);
      });
    }
  });
});
