import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

$(document).ready(() => {
  const $form = $("form#legal-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "fatca") {
    return;
  }

  const $americanLawEntityInputs = $("#american-law-entity-inputs input[type=radio]", $form);
  const $entityCategory = $("#entity-category-inputs", $form);

  $americanLawEntityInputs.on("change", (e) => {
    if (e.target.checked) {
      $entityCategory.toggleClass("d-none", e.target.value !== "true")

      if (e.target.value !== "true") {
        $("input", $entityCategory).each((_, inputElement) => {
          clearInputField(inputElement);
        });
      }
    }
  });
});
