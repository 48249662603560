import {
  getQueryStringParameter,
  clearInputField
} from "../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form").first();

  if ($form.length === 0 || getQueryStringParameter("id") !== "objectives") {
    return;
  }

  const $investmentGoalsCheckBoxes = $("#investment-goals input[type=checkbox]", $form);
  const $investmentGoalsDetails = $("#investment-goals-details", $form);

  $investmentGoalsCheckBoxes.on("change", (e) => {
    if (e.target.value !== "other") return;

    if (e.target.checked) {
      $investmentGoalsDetails.removeClass("d-none");
    } else {
      $investmentGoalsDetails.addClass("d-none");

      $("input", $investmentGoalsDetails).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
});
