import {
  getQueryStringParameter,
} from "../../helpers";

// Suitability:
//   risk_tolerance === "high" &&
//   risks_appreciation_1 == true &&
//   risks_appreciation_2 == true &&
//   risks_appreciation_3 == true &&
//   risks_appreciation_4 == true &&
//   risks_appreciation_5 == false &&
//   risks_appreciation_6 == true &&
//   risks_appreciation_7 == true &&
//   risks_appreciation_8 == true
const suitability = [true, true, true, true, false, true, true, true];

const getRisksAppreciation = (index, $form) => {
  const $risksAppreciation = $(`#risks-appreciation-${index} input[type=radio]`, $form);
  const $trueRisksAppreciation = $risksAppreciation.filter("[value=true]");
  const $falseRisksAppreciation = $risksAppreciation.filter("[value=false]");

  if ($trueRisksAppreciation[0].checked) {
    return true;
  } else if ($falseRisksAppreciation[0].checked) {
    return false;
  } else {
    return undefined;
  }
};

const mustShowConfirmationOnSubmit = ($form) => {
  const riskTolerance = $("#risk-tolerance input[type=radio]:checked", $form).val();

  if (riskTolerance === "low" || riskTolerance === "medium") {
    return true;
  }

  const risksAppreciations = [
    getRisksAppreciation(1, $form),
    getRisksAppreciation(2, $form),
    getRisksAppreciation(3, $form),
    getRisksAppreciation(4, $form),
    getRisksAppreciation(5, $form),
    getRisksAppreciation(6, $form),
    getRisksAppreciation(7, $form),
    getRisksAppreciation(8, $form)
  ];

  for (const [index, element] of risksAppreciations.entries()) {
    if (element === undefined) {
      return false;
    } else if (element !== suitability[index]) {
      return true;
    }
  }

  return false;
};

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form").first();

  if ($form.length === 0 || getQueryStringParameter("id") !== "risks") {
    return;
  }

  const $suitabilityConfirmationModal = $("#suitability-confirmation-modal");

  $form.on("submit", (e, forceSubmit = false) => {
    if (!forceSubmit && mustShowConfirmationOnSubmit($form)) {
      e.preventDefault();

      $suitabilityConfirmationModal.modal("show");
    }
  });

  $("#confirm-suitability").on("click", (e) => {
    $form.trigger("submit", true);
  });
});
