import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

const updateFieldStates = ($form) => {
  const $professionalSituationSelect = $("#professional-situation select", $form);

  const $professionalStatus = $("#professional-status", $form);
  const $profession = $("#profession", $form);

  const $professionalStatusSelect = $("select", $professionalStatus);
  const $professionInput = $("input[type=text]", $profession);

  if ($professionalSituationSelect.val() === "inactive") {
    $professionalStatusSelect.attr("disabled", true);
    $professionInput.attr("disabled", true);

    $professionalStatusSelect.val("none");
    clearInputField($professionInput[0]);

    // This is required to force select2 to update the value
    $professionalStatusSelect.trigger("change");

    $professionalStatus.addClass("d-none");
    $profession.addClass("d-none");
  } else {
    $professionalStatusSelect.removeAttr("disabled");
    $professionInput.removeAttr("disabled");

    $professionalStatus.removeClass("d-none");
    $profession.removeClass("d-none");
  }
};

$(document).ready(() => {
  const $form = $("form#natural-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "professional_situation") {
    return;
  }

  const $professionalSituationSelect = $("#professional-situation select", $form);

  $professionalSituationSelect.on("change", (e) => {
    updateFieldStates($form);
  });

  updateFieldStates($form);
});
