import {
  getQueryStringParameter
} from "../../../helpers";

import { formatNumberWithThousandDelimiter } from "../../../cleave_custom";

$(document).ready(() => {
  const $form = $("form#natural-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "financial_situation") {
    return;
  }

  $(".input-custom").each((_, element) => {
    formatNumberWithThousandDelimiter(element);
  });
});
