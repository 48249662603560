import {
  getQueryStringParameter,
  clearInputField,
} from "../../helpers";

const recomputeMaxInputIndex = ($form) => {
  const otherDocumentsFields = ".profile_other_document_fields";

  let index = 0;

  let $lastFields = $(".profile_other_document_fields", $form).each((_, element) => {
    let inputName = $("input[type=file]", element).attr("name");
    let inputIndex = parseInt(inputName.match(/.+\[.*\]\[(\d+)\]\[.+\]/)[1]);

    if (!isNaN(inputIndex) && inputIndex > index) {
      index = inputIndex;
    }
  });

  return index;
};

const incrementAndApplyMaxInputIndex = ($form, $fields) => {
  let index = recomputeMaxInputIndex($form) + 1;

  $("input", $fields).each((_, element) => {
    let inputNameChuncks = element.name.match(/(.+\[.*\])\[\d+\](\[.+\])/);

    element.name = `${inputNameChuncks[1]}[${index}]${inputNameChuncks[2]}`
    element.id = element.id.replace(/\d+/, index);
  });

  $("label", $fields).each((_, element) => {
    element.setAttribute("for", element.getAttribute("for").replace(/\d+/, index));
  })
};

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form").first();

  if ($form.length === 0) {
    return;
  }

  const otherDocumentsWrapper = ".profile_other_documents_wrapper";
  const otherDocumentsFields = ".profile_other_document_fields";
  const seeDocumentButton = ".see-document";
  const destroyDocumentCheckbox = "input[type=checkbox].destroy_document_checkbox";
  const addButton = ".add_other_document";
  const removeButton = ".remove-document";

  const $addOtherDocumentButton = $(`${otherDocumentsWrapper} ${addButton}`, $form);
  const $removeDocumentButton = $(`${otherDocumentsFields} ${removeButton}`, $form);

  $addOtherDocumentButton.on("click", (e) => {
    let $addButton = $(e.target);
    let $otherDocumentsWrapper = $addButton.closest(otherDocumentsWrapper);
    let $hiddenDocumentsFields = $(`${otherDocumentsFields}.d-none`, $otherDocumentsWrapper);
    let $hiddenDocumentFields = $hiddenDocumentsFields.first();
    let $removeDocumentButton = $(removeButton, $hiddenDocumentFields);

    $addButton.toggleClass("d-none", $hiddenDocumentsFields.length <= 1);
    $hiddenDocumentFields.removeClass("d-none");
    $removeDocumentButton.removeClass("d-none");
  });

  $removeDocumentButton.on("click", (e) => {
    let $removeButton = $(e.target);
    let $otherDocumentFields = $removeButton.closest(otherDocumentsFields);

    let $otherDocumentsWrapper = $otherDocumentFields.closest(otherDocumentsWrapper);
    let $addButton = $(addButton, $otherDocumentsWrapper);
    let $visibleDocumentsFields = $(`${otherDocumentsFields}:not(.d-none)`, $otherDocumentsWrapper);

    if ($visibleDocumentsFields.length == 4) {
      $addButton.removeClass("d-none");
    }

    $otherDocumentFields.addClass("d-none");

    if ($otherDocumentFields.data("document-id") !== undefined) {
      let $otherDocumentFieldsToRemove = $otherDocumentFields.clone();
      let $destroyCheckbox = $(destroyDocumentCheckbox, $otherDocumentFieldsToRemove);

      $destroyCheckbox.prop("checked", true);
      $destroyCheckbox.prev("input[type=hidden]").val("1");

      $otherDocumentFieldsToRemove.removeAttr("id");
      $otherDocumentFieldsToRemove.removeAttr("class");
      $otherDocumentFieldsToRemove.hide();
      $otherDocumentFieldsToRemove.appendTo($form);

      $otherDocumentFields.removeAttr("data-document-id");
      $otherDocumentFields.removeData("document-id");

      incrementAndApplyMaxInputIndex($form, $otherDocumentFields);
    }

    $("input", $otherDocumentFields).each((_, inputElement) => {
      if (!inputElement.name.includes("[type]")) {
        clearInputField(inputElement);
      }
    });

    $removeButton.siblings(seeDocumentButton).remove();
  });

  $form.on("submit", (e) => {
    $(`${otherDocumentsFields}.d-none`, $form).remove();
  });
});
