$(document).ready(() => {
  const $subscriptionForm = $("#subscription-form");

  if ($subscriptionForm.length === 0) {
    return;
  }

  let $userProfileStaticWrapper = $("#user-profile-static-wrapper", $subscriptionForm);
  let $changeUserProfile = $("#change-user-profile", $userProfileStaticWrapper);

  let $subscriptionUserProfileIDField = $("#subscription-user-profile-field", $subscriptionForm);
  let $subscriptionUserProfileIDSelect = $("#subscription-user-profile-select", $subscriptionUserProfileIDField);

  $changeUserProfile.on("click", (e) => {
    e.preventDefault();

    $subscriptionUserProfileIDField.removeClass("d-none");
    $userProfileStaticWrapper.remove();
  });
});
