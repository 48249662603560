import {
  getQueryStringParameter,
} from "../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form").first();
  const universignIframeContainer = "universign-iframe-container";
  const $universignIframeContainer = $(`#${universignIframeContainer}`, $form);

  if ($form.length === 0 || getQueryStringParameter("id") !== "signature" || $universignIframeContainer.length === 0) {
    return;
  }

  const universignSignerID = $universignIframeContainer.data("universign-signer-id");
  const universignPlatformURL = $universignIframeContainer.data("universign-platform-url");

  universignSigInit(universignIframeContainer, universignSignerID, { redirectionMode: "OUT" }, universignPlatformURL);
});
