$(document).ready(() => {
  $(".document-field .custom-file-input").on("change", (e) => {
    const $input = $(e.target);

    if ($input[0].files[0] !== undefined) {
      const $customFileWrapper = $input.parents(".custom-file");
      const $labelFile = $customFileWrapper.find(".custom-file-label");
      const $browseBtn = $customFileWrapper.find(".custom-browse-btn");

      $labelFile.html($input[0].files[0].name);
      $labelFile.addClass("border-success").removeClass("border-primary");
      $browseBtn.addClass("btn-outline-success").removeClass("btn-outline-primary");
    }
  });

  $(".document-field .remove-document").on("click", (e) => {
    const $removeButton = $(e.target);
    const $customFileWrapper = $removeButton.parents(".document-field").find(".custom-file");
    const $labelFile = $customFileWrapper.find(".custom-file-label");
    const $browseBtn = $customFileWrapper.find(".custom-browse-btn");

    $labelFile.html($labelFile.data("text"));
    $labelFile.removeClass("border-success").addClass("border-primary");
    $browseBtn.removeClass("btn-outline-success").addClass("btn-outline-primary");
  });
});
