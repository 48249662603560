$(document).ready(() => {
  const universignIframeContainer = "universign-iframe-container";
  const $universignIframeContainer = $(`#${universignIframeContainer}`, "#user-subscription-signature-container");

  if ($universignIframeContainer.length === 0) {
    return;
  }

  const universignSignerID = $universignIframeContainer.data("universign-signer-id");
  const universignPlatformURL = $universignIframeContainer.data("universign-platform-url");

  universignSigInit(universignIframeContainer, universignSignerID, { redirectionMode: "OUT" }, universignPlatformURL);
});
