import Cleave from "cleave.js";

export const formatNumberWithThousandDelimiter = (selector) => {
  if ($(selector).length > 0) {
    new Cleave(selector, {
      numeral: true,
      numeralPositiveOnly: true,
      numeralThousandsGroupStyle: "thousand",
      delimiter: " ",
    });
  }
};
