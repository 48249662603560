// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap";
import Tooltip from "bootstrap/js/dist/tooltip";

import toastr from "toastr";
import "select2";
import "@fortawesome/fontawesome-free/js/all"

import { phoneNumberFormatter } from "./application/phoneNumberFormatter";

import "./application/users/profiles/legal/beneficiaries_step.js";
import "./application/users/profiles/legal/fatca_step.js";

import "./application/users/profiles/natural/additional_info_step.js";
import "./application/users/profiles/natural/documents_step.js";
import "./application/users/profiles/natural/fatca_step.js";
import "./application/users/profiles/natural/professional_situation_step.js";
import "./application/users/profiles/natural/financial_situation_step.js";

import "./application/users/profiles/fatca_step.js";
import "./application/users/profiles/objectives_step.js";
import "./application/users/profiles/risks_step.js";
import "./application/users/profiles/signature_step.js";

import "./application/users/profiles/optional_documents.js";
import "./application/users/profiles/other_documents.js";
import "./application/users/profiles.js";

import "./application/users/subscriptions/signature.js";
import "./application/subscriptions.js"
import "./application/file_inputs.js"
import "./application/mobile_header.js"
import "./application/select2_custom.js";

toastr.options.toastClass = 'toastr';

function chooseProfileForInvestment() {
  if ($('select#profile_id')[0] && $('a#select-share-link')[0]) {
    const profile = $('select#profile_id').val();

    $('a#select-share-link').attr('href', $('a#select-share-link').attr('href').replace(/&profile_id=\d*&?/, '') + `&profile_id=${profile}`);
  }
}

$(document).ready(() => {
  if ($('.flash.info')[0]) {
    toastr["info"]($('.flash.info').html());
  }
  if ($('.flash.success')[0]) {
    toastr["success"]($('.flash.success').html());
  }
  if ($('.flash.error')[0]) {
    toastr["error"]($('.flash.error').html());
  }
  if ($('.flash.alert')[0]) {
    toastr["error"]($('.flash.alert').html());
  }

  const $cta = $(".cta");

  if ($cta.length > 0) {
    toastr.info($cta.html(), "",  {
      closeButton: true,
      timeOut: 0,
      extendedTimeOut: 0
    });
  }


  $("input[type=tel]").each((index, element) => {
    phoneNumberFormatter(element);
  });

  $('select#profile_id').on('change', chooseProfileForInvestment);
  chooseProfileForInvestment();

  const $customDatePicker = $(".custom-datepicker");

  if ($customDatePicker.length > 0) {
    $customDatePicker.each((_, element) => {
      let $element = $(element);

      if ($element.val().length > 0) {
        $element.addClass("border-success");
      }
    });

    $customDatePicker.on("change", (e) => {
      const $datePicker = $(e.target);

      if ($datePicker.val().length > 0) {
        $datePicker.addClass("border-success");
      } else {
        $datePicker.removeClass("border-success");
      }
    });
  }

  $("[data-bs-toggle=\"tooltip\"]").each((_, element) => {
    new Tooltip(element);
  });
});
