$(document).ready(() => {
  const $isPoliticallyExposedPersonInputs = $("#is-politically-exposed-person-inputs input[type=radio]", "#natural-profile-form");
  const $politicallyExposedPersonFields = $("#politically-exposed-person-fields", "#natural-profile-form");

  const $isCloseToPoliticallyExposedPersonInputs = $("#is-close-politically-exposed-person-inputs input[type=radio]", "#natural-profile-form");
  const $closePoliticallyExposedPersonFields = $("#close-politically-exposed-person-fields", "#natural-profile-form");

  $isPoliticallyExposedPersonInputs.on("change", (e) => {
    if (e.target.checked) {
      $politicallyExposedPersonFields.toggleClass("d-none", e.target.value !== "true")
    }
  });

  $isCloseToPoliticallyExposedPersonInputs.on("change", (e) => {
    if (e.target.checked) {
      $closePoliticallyExposedPersonFields.toggleClass("d-none", e.target.value !== "true")
    }
  });
});
