import {
  getQueryStringParameter,
} from "../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form").first();

  if ($form.length === 0 || getQueryStringParameter("id") !== "fatca") {
    return;
  }

  const $addFatcaFiscalCountryButton = $("#add-fatca-fiscal-country", $form);
  const $fatcaFiscalCountry2 = $("#fatca-fiscal-country2", $form);
  const $fatcaFiscalCountry3 = $("#fatca-fiscal-country3", $form);

  $addFatcaFiscalCountryButton.on("click", (e) => {
    e.preventDefault();

    if ($fatcaFiscalCountry2.hasClass("d-none")) {
      $fatcaFiscalCountry2.removeClass("d-none");
    } else if ($fatcaFiscalCountry3.hasClass("d-none")) {
      $fatcaFiscalCountry3.removeClass("d-none");
    }

    if (!$fatcaFiscalCountry2.hasClass("d-none") && !$fatcaFiscalCountry3.hasClass("d-none")) {
      $addFatcaFiscalCountryButton.addClass("d-none");
    }
  });
});

