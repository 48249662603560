import intlTelInput from "intl-tel-input";
import telUtils from "intl-tel-input/build/js/utils";

import { getNextSibling } from "./helpers";

export const phoneNumberFormatter = (input, errorMessage) => {
  let errorMessageElement = errorMessage;

  if (!(errorMessageElement instanceof Element)) {
    errorMessageElement = input.nextElementSibling;

    if (!(errorMessageElement instanceof Element && errorMessageElement.matches(".phone-number-error"))) {
      errorMessageElement = null;
    }
  }

  const markAsInvalid = () => {
    input.classList.add("is-invalid");

    if (errorMessageElement) {
      errorMessageElement.classList.remove("d-none");
    }
  };

  const reset = () => {
    input.classList.remove("is-invalid");

    if (errorMessageElement instanceof Element) {
      errorMessageElement.classList.add("d-none");
    }
  };

  const iti = intlTelInput(input, {
    preferredCountries: ["fr"],
    initialCountry: "fr",
    utilsScript: telUtils,
    formatOnDisplay: false,
  });

  const updatePhoneNumber = () => {
    const intNumber = iti.getNumber();
    input.value = intNumber;
  };

  const checkPhoneNumber = () => {
    reset();
    updatePhoneNumber();

    if (input.value.trim() && !iti.isValidNumber()) {
      markAsInvalid();
    }
  };

  input.addEventListener("change", () => {
    updatePhoneNumber();
  });

  input.addEventListener("countrychange", () => {
    updatePhoneNumber();
  });

  input.addEventListener("blur", () => {
    checkPhoneNumber();
  });

  input.addEventListener("load", reset());
  input.addEventListener("change", reset());
  input.addEventListener("keyup", reset());

  checkPhoneNumber();
};
